/* app.css */

/* Global styles */
body {
  background-color: #131313;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

.App {
  min-height: 92vh; /* Add this line */
}

.content {
  flex: 1;
}

.container {
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
  max-width: 1200px;
}

/* Responsive styles */
@media (max-width: 767px) {
  .container {
    padding: 10px;
    margin: 10px;
  }
}
