/* lineupGuesses.css */

.lineup-guess-form {
  margin-bottom: 20px;
}

.circles-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.circle-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.circle.correct {
  background-color: #4caf50;
}

.circle.incorrect {
  background-color: #ff0000;
}

.player-info {
  text-align: center;
}

.player-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.metric {
  font-size: 14px;
  margin-bottom: 5px;
}

.error-message {
  font-size: 14px;
  color: #ff0000;
}

@media screen and (max-width: 600px) {
  .circles-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.error-message {
  font-size: 14px;
  color: #ff0000;
  background-color: #ffcccc;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;
}

@media (max-width: 767px) {
  .lineup-guess-form {
    margin-bottom: 10px;
  }
  .lineup-guess-form input {
    width: 100%;
    margin-bottom: 10px;
  }
}
