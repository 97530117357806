.TeamsLandingPage {
  text-align: center;
}

.TeamsLandingPage .container {
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  margin-top: 0px;
  padding-top: 0px;
}

.TeamsLandingPage .team-box {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  border: 3px solid;
}

.TeamsLandingPage .team-box:hover {
  transform: scale(1.05);
}

.TeamsLandingPage .team-box h2 {
  margin: 0;
  font-size: 16px;
}

.select-team-text {
  text-align: left;
  align-self: flex-start; /* Align text to the start of the flex container */
  margin-bottom: 6px;
}

.select-sub-text {
  text-align: left;
  margin-top: 0px;
}

.team-grid {
  display: grid;
  grid-template-columns: inherit; /* Inherit grid properties from .container */
  gap: 20px;
}

.TeamsLandingPage a {
  text-decoration: none;
}

@media (max-width: 767px) {
  .TeamsLandingPage .container {
    margin: 0 auto;
    padding: 0px 20px 20px 20px;
    max-width: 500px;
  }
  .select-team-text {
    margin-top: 8px;
    padding-bottom: 0px;
  }
  .select-sub-text {
    text-align: left;
    margin: 0px auto 16px 0px;
    font-size: 14px;
  }
}
