.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  padding: 20px;
  border-radius: 8px;
  max-width: 689px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.modal-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333333;
}

.toggle-button {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-button button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #f0f0f0;
  color: #333333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 20px;
  margin: 0 5px;
}

.toggle-button button.selected {
  background-color: #007bff;
  color: #ffffff;
}
.toggle-button button:not(.selected) {
  background-color: #fff;
  color: #333;
}

.stats-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stats-item {
  text-align: center;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  flex: 1;
  margin: 0 5px;
}

.stats-label {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.stats-value {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.bar-chart {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 40px;
  margin-bottom: 20px;
}

.bar-chart-item {
  display: flex;
  align-items: center;
  position: relative;
}

.bar-chart-label {
  position: absolute;
  left: -40px;
  width: 30px;
  text-align: right;
  font-weight: bold;
}

.bar-chart-bar {
  height: 24px;
  background-color: #e0e0e0;
  color: #333;
  text-align: right;
  padding-right: 8px;
  transition: width 0.3s ease;
  border-radius: 12px;
  position: relative;
  min-width: 40px;
}

.bar-chart-bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  background-color: #f8f8f8;
  z-index: -1;
  border-radius: 12px 0 0 12px;
}

.bar-chart-bar-value {
  margin-left: 8px;
}

.modal-content button {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  background-color: #6c757d;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 20px;
  margin-top: 10px;
}

.modal-content button:hover {
  background-color: #0056b3;
}

.highlight-yellow {
  background-color: yellow;
  color: #000;
  font-weight: bold;
}

.highlight-green {
  background-color: green;
  color: white;
  font-weight: bold;
}

@media (max-width: 767px) {
  .modal-overlay {
    bottom: 0;
    align-items: flex-end;
  }
  .modal-content {
    max-width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
    overflow-y: auto;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }
  .stats-value {
    font-size: 16px;
  }

  .stats-label {
    font-size: 10px;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 1rem;
  width: 100%;
}
