/* components/global/Footer.css */
.footer {
  background-color: #131313;
  padding: 20px 0;
  text-align: center;
  font-size: 10px;
  color: #888;
}

.footer .container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer .disclaimer {
  margin: 0;
}
