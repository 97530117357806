.guess-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.guesses-list {
  margin-top: 20px;
  border-radius: 5px;
  overflow: hidden;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0;
}

th,
td {
  padding: 8px;
  text-align: left;
  border: none;
  border-bottom: 1px solid #ddd;
  height: 40px;
}

.guess-item {
  margin-bottom: 20px;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

thead {
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.2);
}

tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.5);
}

.guess-item table {
  width: 100%;
  border-collapse: collapse;
}

.guess-item th,
.guess-item td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.guess-item th {
  background-color: #f2f2f2;
}

.position {
  font-weight: bold;
}

.green {
  background-color: #27ae60;
  color: white;
  font-weight: bold;
}

.red {
  background-color: #e74c3c;
  color: white;
}

.yellow {
  background-color: #f1c40f;
  font-weight: bold;
}

.arrow {
  margin-left: 5px;
  font-size: 14px;
}

.gradient-background-guesser {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 64px;
  z-index: -1;
}

th.guess-number,
td.guess-number {
  width: 10%; /* example width, adjust as needed */
}

th.name,
td.name {
  width: 25%; /* example width, adjust as needed */
}

th.position,
td.position {
  width: 10%; /* example width, adjust as needed */
}

th.country,
td.country {
  width: 25%; /* example width, adjust as needed */
}

th.jersey-number,
td.jersey-number {
  width: 10%; /* example width, adjust as needed */
}

th.first-year,
td.first-year {
  width: 10%; /* example width, adjust as needed */
}

th.points,
td.points {
  width: 10%; /* example width, adjust as needed */
}

@keyframes flipAnimation {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

td.animated {
  animation: flipAnimation 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

@media (max-width: 768px) {
  .guess-form {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 12px;
  }

  .guesses-list {
    margin-top: 0px;
  }

  .gradient-background .container {
    margin: 0 auto;
  }

  table {
    font-size: 10px;
  }

  th,
  td {
    padding: 6px;
  }

  .player-search {
    display: grid;
    grid-template-columns: 3fr 1fr;
  }

  th.guess-number,
  td.guess-number {
    width: 10%; /* example width, adjust as needed */
  }

  th.name,
  td.name {
    width: 18%; /* example width, adjust as needed */
  }

  th.position,
  td.position {
    width: 13%; /* example width, adjust as needed */
  }

  th.country,
  td.country {
    width: 15%; /* example width, adjust as needed */
  }

  th.jersey-number,
  td.jersey-number {
    width: 15%; /* example width, adjust as needed */
  }

  th.first-year,
  td.first-year {
    width: 10%; /* example width, adjust as needed */
  }

  th.points,
  td.points {
    width: 10%; /* example width, adjust as needed */
    padding-right: 10px;
  }

  .gradient-background-guesser {
    top: 54px;
  }
}

a {
  color: #cccccc;
  font-weight: bold;
}

.game-over {
  margin: 0 auto;
  text-align: center;
}

.correct-player-name {
  display: flex;
  align-items: center;
  height: 20px;
  /* justify-content: center; */
  /* margin-bottom: 10px; */
}

.correct-player-name h3 {
  margin-right: 5px;
}

.correct-player-name p {
  font-weight: bold;
}
