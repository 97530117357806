/* PlayerRankingGame.css */
.player-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}

.player-card {
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0;
  width: 90%; /* Adjust the width for better mobile compatibility */
  text-align: center;
  cursor: move;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.player-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.player-name {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.ranking-bubble {
  position: absolute;
  top: -10px; /* Adjust the top position */
  left: -10px; /* Adjust the left position */
  width: 40px; /* Adjust the width */
  height: 40px; /* Adjust the height */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.ranking-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ranking-number {
  font-size: 14px; /* Adjust the font size */
  line-height: 1;
  color: #000;
}

.ranking-metric {
  font-size: 10px; /* Adjust the font size */
  line-height: 1;
  color: #fff;
}

.gradient-background-playerRanking {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 64px;
  z-index: -1;
}

.submit-guess-btn {
  background: #fff;
  color: #000;
  border-radius: 5px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;
}
.game-status {
  margin-top: 0px;
}

.game-status.win {
  color: green;
}

.game-status.loss {
  color: red;
}

.try-again-text {
  font-size: 14px;
  color: #fff;
  margin-left: 5px;
  font-weight: 300;
}

.page-container .container {
  margin-top: 0px;
}

@media (max-width: 768px) {
  .page-container .container {
    margin: 0 auto;
    max-width: 95%;
  }

  .gradient-background .container {
    margin: 0 auto;
    max-width: 95%;
  }
  .gradient-background {
    top: 54px;
  }

  .game-status {
    font-size: 14px;
  }
  .game-explainer {
    font-size: 12px;
  }
  .try-again-text {
    font-size: 10px;
  }
}
