@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

h1 {
  margin: 0;
  text-align: center;
  flex-grow: 1;
  font-size: 20px;
}

li a {
  text-decoration: none;
}
