.helper-modal {
  position: relative;
  padding: 20px;
  background: #000000;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  text-align: left;
}

.helper-modal h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffffff;
}

.helper-modal {
  font-size: 16px;
  margin-bottom: 20px;
}

.helper-modal ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

.helper-modal li {
  margin-bottom: 10px;
}

.helper-modal .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}

.divider-line {
  width: 100%;
  height: 1px;
  background-color: #cccccc;
  margin-top: 20px;
}

.bottom-message {
  font-size: 12px;
}

@media (max-width: 767px) {
  .helper-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 8px 8px 0 0;
    font-size: 14px;
  }

  .helper-modal h2 {
    font-size: 18px;
  }
}
