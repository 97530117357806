/* playerSearch.css */
input {
  font-size: 16px;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.player-search {
  display: flex;
  flex: 1;
  align-items: stretch;
  gap: 0;
  position: relative;
  border-style: none;
  justify-content: center;
}

.search-container {
  max-width: 578px;
  height: 58px;
  display: flex;
  border-color: #ffffff;
  flex: 1;
  position: relative;
}

.search-container input {
  border-color: #ffffff;
  border-style: none;
  flex: 1;
  border-radius: 5px 0 0 5px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-family: "Montserrat";
  padding-left: 20px;
}

.search-container input::placeholder {
  color: #ffffff;
}

.player-search-submit {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background: #ffffff;
  color: #000;
  border-radius: 0px 5px 5px 0px;
  padding: 0 31px;
  border-color: #ffffff;
  border-style: none;
}

.player-search-submit.disabled {
  cursor: not-allowed;
}

.search-results {
  position: absolute;
  box-sizing: border-box; /* Include padding and border in the width */
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-top: none;
  z-index: 1000;
}

.search-result {
  padding: 8px;
  cursor: pointer;
  color: #000;
}

.search-result:hover {
  background-color: #f4f4f4;
}

.submit-guess-btn {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Add some space above the button */
}

@media (max-width: 767px) {
  .player-search input {
    font-size: 14px;
  }

  .search-results {
    max-height: 150px;
  }

  .search-result {
    padding: 6px;
    font-size: 14px;
  }

  .player-search-submit {
    font-size: 14px;
    padding: 8px 16px;
  }

  .player-search {
    flex-direction: column;
    align-items: stretch;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 10px;
  }

  .input-container input {
    width: 100%;
    margin-bottom: 10px;
  }
}
