.condition-info {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.condition-info p {
  margin: 0;
}

.condition-info .condition-metric {
  color: #2c3e50;
  font-size: 20px;
}

.condition-info .flag-icon {
  margin-right: 5px;
  color: #2c3e50;
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .lineup-conditions {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 767px) {
  .lineup-conditions {
    padding: 10px;
    margin-bottom: 10px;
  }

  .condition-info {
    font-size: 16px;
  }
}
