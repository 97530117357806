nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

nav h1 {
  flex-grow: 1; /* Allow the h1 to take up remaining space */
  text-align: center; /* Center align the text */
  font-size: 24px;
}

.nav-icon {
  height: 40px;
  width: 40px;
}

.nav-icon-help {
  height: 32px;
  width: 32px;
}

.helper-icon {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.helper-icon:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.home-icon {
  color: #ffffff;
  text-decoration: none;
}

@media (max-width: 767px) {
  nav {
    /* height: 55px; */
    padding: 10px;
    align-items: stretch; /* Change this line */
  }
  .nav-title {
    margin: 0;
    font-size: 20px;
    align-self: center;
    text-align: left;
    padding-left: 12px;
  }
  .home-icon {
    width: 32px;
    height: 32px !important;
    align-self: center; /* Add this line */
  }

  .nav-icon {
    width: 32px;
    height: 32px;
  }

  .helper-icon {
    width: 25px;
    height: 25px;
    font-size: 20px;
  }

  .home-icon,
  .nav-icon-help {
    align-self: center; /* Add this line */
  }
}
