.game-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: center;
  gap: 20px;
  margin-top: 20px; /* Add margin-top to create space below the title */
  max-width: 80%;
  margin: 0 auto;
}

.game-title-container {
  margin-bottom: 20px;
  text-align: left;
}

.game-title-container h1 {
  font-size: 20px;
  font-weight: normal;
}

.game-box {
  background-color: #f0f0f0;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  max-width: 433px; /* Change this line */
  box-sizing: border-box;
  text-decoration: none;
}

.TeamLandingPage a {
  text-decoration: none;
}

.game-box-top {
  border-radius: 5px 5px 0px 0px;
  background-color: #511323;
  color: #fff;
  padding: 10px 20px 50px 20px;
  margin: 0;
  text-align: left;
  height: 90px;
  box-sizing: unset;
  font-size: 32px;
  display: flex;
  align-items: flex-end;
}

.game-box-text {
  background-color: #000;
  color: #fff;
  height: 108px;
  display: block;
  align-items: center;
  padding: 15px;
  margin: 0;
  text-align: left;
  border-radius: 0 0 5px 5px;
  font-weight: 400;
  line-height: normal;
  box-sizing: unset;
}
.level-chip {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #ccc;
  padding: 5px 10px;
  border-radius: 0 5px 0 5px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.level-medium {
  background-color: #ffeb3b;
}

.level-hard {
  background-color: #f44336;
  color: #fff;
}

@media (max-width: 767px) {
  .game-container {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 90%;
  }

  .game-box-top {
    height: 60px;
    padding: 10px 10px 30px 10px;
    font-size: 16px;
  }

  .game-box-text {
    font-size: 12px;
    height: auto;
  }

  .game-box {
    width: unset;
  }

  .game-title-container h1 {
    font-size: 12px;
  }
}

.skeleton {
  background-color: #f0f0f0;
}

.skeleton-top {
  height: 102px;
  background-color: #dddbdd;
  position: relative;
  overflow: hidden;
}

.skeleton-top::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 1.5s infinite;
}

.skeleton-text {
  height: 108px;
  background-color: #000;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.gradient-background-teamLanding {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 64px;
  z-index: -1;
}
